.footer {
    position: relative;
    display: flex;
    flex-direction: column;

    &-top {
        &__container {
            display: flex;
            justify-content: space-between;
            padding: 100px 15px 110px;
        }

        &__content {
            width: 770px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 55px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 45px;
    }

    &__logo {
        width: 271px;
        height: 129px;
    }

    &__contact {
        width: 270px;

        &:hover,
        &:focus {
            color: $color-text !important;
        }
    }

    &-mairie-horaires {
        display: flex;
        column-gap: 95px;
    }

    &__mairie {
        width: 205px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin: 0;
    }

    &__text {
        color: $color-text;
        margin-top: 5px;
    }

    &__tel {
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin-top: 15px;

        &:hover,
        &:focus {
            color: $color-second;
        }

        svg {
            width: 26px;
            height: 26px;
            fill: $color-second;
        }
    }

    &__horaires {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            position: relative;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-text;

            &::after {
                content: url("data:image/svg+xml,%3Csvg viewBox='0 0 69 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M65.0796 0.5061C65.9047 0.43204 66.6336 1.04089 66.7076 1.866L68.494 21.7678C68.5595 22.4975 68.0883 23.1678 67.3795 23.3531L47.886 28.4513C47.0845 28.6609 46.2649 28.1811 46.0553 27.3796C45.8457 26.5782 46.3255 25.7585 47.1269 25.5489L64.1026 21.1092C39.245 -3.16232 12.935 3.82184 2.81451 10.3661C2.11885 10.816 1.19025 10.6167 0.740407 9.92103C0.290569 9.22537 0.489843 8.29677 1.1855 7.84693C12.0883 0.796768 39.399 -6.28087 65.139 17.9466L63.7197 2.13419C63.6456 1.30908 64.2544 0.580161 65.0796 0.5061Z' fill='%232B78AC'/%3E%3C/svg%3E");
                width: 68px;
                height: 28px;
                position: absolute;
                top: 0;
                right: -70px;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            column-gap: 30px;

            a {
                display: flex;
            }
        }

        &__item {
            &--villes-demain {
                width: 102px;
                height: 68px;
            }
    
            &--villes-bonvivre {
                width: 112px;
                height: 71px;
            }
    
            &--station-verte {
                width: 100px;
                height: 38px;
            }
    
            &--terre-jeux {
                width: 71px;
                height: 71px;
            }
    
            &--sport-tous {
                width: 102px;
                height: 38px;
            }
        }
    }

    &-bottom {
        height: 75px;
        background-color: $color-light;

        &__container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__menu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 70px;
    }

    &__link {
        color: $color-text;
        padding: 5px 0;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-link;
        }
    }
}



// 1200
@media screen and (max-width: $large) {
    .footer {    
        &-top {
            &__container {
                padding: 80px 10px 70px;
            }
    
            &__content {
                width: 640px;
                row-gap: 50px;
            }
        }
    
        &__logo {
            width: 250px;
            height: 120px;
        }
    
        &__contact {
            width: 250px;
        }
    
        &-mairie-horaires {
            column-gap: 35px;
        }
    
        &-labels {    
            &__title {
                &::after {
                    right: -35px;
                }
            }
    
            &__content {
                column-gap: 10px;
            }

            &__item {
                &--villes-demain {
                    width: 98px;
                    height: 66px;
                }
        
                &--villes-bonvivre {
                    width: 108px;
                    height: 69px;
                }
        
                &--station-verte {
                    width: 96px;
                    height: 35px;
                }
        
                &--terre-jeux {
                    width: 68px;
                    height: 68px;
                }
        
                &--sport-tous {
                    width: 98px;
                    height: 36px;
                }
            }
        }
    
        &__menu {
            justify-content: space-between;
            column-gap: 0;
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .footer {    
        &-top {

            &__container {
                position: relative;
                padding: 70px 10px 175px;
            }
    
            &__content {
                width: auto;
            }
        }
    
        &-mairie-horaires {
            flex-direction: column;
            row-gap: 30px;
        }

        &__mairie,
        &__horaires {
            width: 300px;
        }
    
        &-labels {    
            width: calc(100% - 20px);
            position: absolute;
            left: 10px;
            bottom: 50px;

            &__title {
                &::after {
                    right: -40px;
                }
            }

            &__item {
                &--villes-demain {
                    width: 94px;
                    height: 64px;
                }
        
                &--villes-bonvivre {
                    width: 103px;
                    height: 67px;
                }
        
                &--station-verte {
                    width: 92px;
                    height: 33px;
                }
        
                &--terre-jeux {
                    width: 65px;
                    height: 65px;
                }
        
                &--sport-tous {
                    width: 94px;
                    height: 34px;
                }
            }
        }

        &-bottom {
            height: 140px;
        }
    
        &__menu {
            flex-wrap: wrap;
            justify-content: flex-start;
            column-gap: 70px;
            row-gap: 20px;
        }
    }
}



// 640
@media screen and (max-width: $small) {
    .footer {    
        &-top {
            &__container {
                flex-direction: column;
                align-items: center;
                row-gap: 45px;
                padding: 70px 10px 50px;
            }

            &__content {
                row-gap: 55px;
            }
        }

        &__links svg {
            width: 250px;
            height: 120px;
        }

        &__mairie,
        &__horaires {
            align-items: center;
        }

        &__title,
        &__text {
            text-align: center;
        }
    
        &-labels {    
            width: 100%;
            position: relative;
            left: unset;
            bottom: unset;
            flex-direction: column;
            align-items: center;
            column-gap: 20px;
            row-gap: 20px;
            padding: 0 20px;

            &__title {
                text-align: center;

                &::after {
                    top: -10px;
                    right: unset;
                    left: -80px;
                }
            }

            &__content {
                flex-wrap: wrap;
                justify-content: center;
                column-gap: 25px;
                row-gap: 25px;
            }
        }

        &-bottom {
            height: 210px;
        }
    
        &__menu {
            justify-content: center;
        }
    }
}