//=================================================================================================
// Avoid main content
//=================================================================================================
.avoid-main-content {
    width: max-content;
    height: 0;
    position: relative;
    display: block;
    color: $color-white;
    text-align: center;
    background: $color-black;
    border-radius: 30px;
    opacity: 0;
    box-sizing: border-box;
    z-index: 1000;
    transition: $duration;

    &:focus {
        height: auto;
        color: $color-white;
        padding: 15px 30px;
        margin: 10px;
        opacity: 1;
    }
}   

.a11y-avoid-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    background-color: $color-white;
    opacity: 0;
    transition: $duration; // situationnel
    pointer-events: none;

    &:focus-within {
        height: auto;
        padding: 10px;
        opacity: 1;
    }
} 

//=================================================================================================
// Classic clearfix 
//=================================================================================================
.clearfix::before,
.clearfix::after {
    content: " ";
    display: block;
}

.clearfix:after {
    clear: both;
}


//=================================================================================================
// Text alignment
//=================================================================================================
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}


//=================================================================================================
// Display
//=================================================================================================
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}


//=================================================================================================
// Vertical center
//=================================================================================================
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


//=================================================================================================
// Responsive images
//=================================================================================================
.responsive-image {
    max-width: 100%;
    height: auto;
}


//=================================================================================================
// Display State
//=================================================================================================
.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.invisible {
    visibility: hidden;
}


//=================================================================================================
// Floats
//=================================================================================================
.float-left {
    float: left;
}

.float-right {
    float: right;
}


//=================================================================================================
// Padding
//=================================================================================================
.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.padding-top {
    padding-top: 2rem;
}

.padding-bottom {
    padding-bottom: 2rem;
}


//=================================================================================================
// Margins
//=================================================================================================
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-top {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}


//=================================================================================================
// Screen reader text for accessibility
//=================================================================================================
.screen-reader-text {
    width: 1px;
    height: 1px;
    position: absolute;
    word-wrap: normal !important;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    padding: 0;
    border: 0;
    margin: -1px;
    overflow: hidden;
}


//=================================================================================================
// Global site
//=================================================================================================
.global-wrapper {
    overflow: hidden;
}