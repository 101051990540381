.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: 2px solid $color-white !important;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            fill: $color-text;
            transition: all $duration;
        }

        &:hover {
            svg {
                fill: $color-main;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-top: 2px solid $color-main;

    li {
        position: relative;
        background: $color-white;
        border-bottom: 2px solid $color-main;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-text;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-text;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search__effect {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

    svg {
        width: 2100px;
        height: 202px;
        stroke-width: 3px;
        stroke: $color-black;
        fill: transparent;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: $color-dark;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-white;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                background: $color-white;
                border-width: 2px;
                border-color: $color-white;
                border-radius: 50px;
            }
        }

        .searchResult {
            width: calc(100% - 270px);
            left: 135px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        background: $color-dark;
        border: 2px solid$color-white;
        border-radius: 50%;
        transition: all $duration;
        
        svg {
            width: 25px;
            height: 25px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover,
        &:focus {
            background: $color-white;

            svg {
                fill: $color-text;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .searchInput {
            color: $color-white;
            border-color: $color-white;

            &::placeholder {
                color: $color-light;
            }
        }

        .search-svg svg {
            fill: $color-white;

            &:hover,
            &:focus {
                fill: $color-third;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    
    .search-popup .container .searchResult {
        left: 105px;
        width: calc(100% - 210px);
    }

}


// 960
@media screen and (max-width: $medium) {
    .search__effect {
        top: 185px;

        svg {
            stroke-width: 5px;
        }
    }

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }

    .search-popup .container .searchResult {
        left: 35px;
        width: calc(100% - 70px);
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {
    .search__effect {
        top: 220px;
    
        svg {
            width: 900px;
            height: 86px;
            stroke-width: 7px;
        }
    }

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

    .search-container .search-svg {
        right: 15px;
    }
}
