.cover {
    position: relative;
    display: flex;
    flex-direction: column;
    
    &::after {
        content: '';
        width: 100%;
        height: calc(100% - 155px);
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $color-light;
    }

    &__background {
        position: absolute;
        top: 155px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        svg {
            width: 2100px;
            height: 150px;
            fill: $color-white;
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    &__image {
        width: 1170px;
        height: 450px;
        background: $color-bg--image;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__content {
       margin: 7.5px 0 45px;
    }

    &__title {
        position: relative;
        color: $color-text;
        margin: 0;
    }

    &__text {
        font-size: $font-size--5;
        line-height: 1.4em;
        color: $color-text;
        margin-top: 20px;
    }
}

.search,
.error404 {
    .cover__content {
        margin-top: 25px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {  
            &::after {
                height: calc(100% - 120px);
            }  

            &__background {
                top: 120px;

                svg {
                    width: 1680px;
                    height: 120px;
                }
            }

            &__image {
                width: 940px;
                height: 400px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {  
            &::after {
                height: calc(100% - 90px);
            }  

            &__background {
                top: 90px;

                svg {
                    width: 1345px;
                    height: 95px;
                }
            }

            &__image {
                width: 620px;
                height: 370px;
            }
        }
    }


// 640
@media screen and (max-width: $small) {
    .cover {    
        &::after {
            height: calc(100% - 45px);
        }  

        &__background {
            top: 45px;
        }

        &__image {
            width: 300px;
            height: 220px;
        }

        &__container {
            align-items: center;
        }

        &__title,
        &__text {
            text-align: center;
        }
    }
}