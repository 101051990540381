.bloc-content--404 {
    h2 {
        margin: 0;
        border: 0;
        font-size: 12.5rem;
        text-align: center;

        &::after {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .searchInput {
        border-color: $color-dark !important;
    }

    .searchResult {
        top: -3px;
        border: 2px solid $color-dark;
        border-radius: 0 0 $border-radius $border-radius;

        li {
            border-color: $color-dark;

            &:not(.no-results) a {
                &:hover,
                &:focus {
                    background-color: $color-dark;
                }
            }
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 10rem;
        }
    }

}
