//======================================================================================================
// Global
//======================================================================================================
body {
    margin-top: 170px;
    transition: $duration;

    &.sticky {
        margin-top: 80px;
    }
}

.front-page,
.footer {
    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 0;
        margin: 0;
        border: 0;

        &::after {
            display: none;
        }
    }
}

.home-button {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    font-size: $font-size--text;
    line-height: 1.1em;
    font-weight: $font-weight-semibold;
    color: $color-white;
    text-align: center;
    background-color: $color-main;
    border: 2px solid $color-main;
    border-radius: 30px;
    transition: $duration;

    &:hover,
    &:focus {
        color: $color-dark;
        background-color: transparent;
        border-color: $color-main;

        svg {
            fill: $color-dark;
        }
    }

    &--second {
        background-color: $color-second;
        border-color: $color-second;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-second;

            svg {
                fill: $color-white;
            }
        }
    }

    &--third {
        color: $color-text;
        background-color: $color-third;
        border-color: $color-third;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-third;
        }
    }

    &--fourth {
        background-color: $color-fourth;
        border-color: $color-fourth;

        &:hover,
        &:focus {
            color: $color-text;
            border-color: $color-fourth;
        }
    }

    &--border {
        background-color: transparent;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;
            border-color: $color-main;
        }

        &-third {
            border-color: $color-third;

            &:hover,
            &:focus {
                color: $color-text;
                background-color: $color-third;
                border-color: $color-third;
            }
        }
    }
}





//======================================================================================================
// SlideShow
//======================================================================================================
.slideshow {
    width: 100%;
    position: relative;
    background: linear-gradient(0deg, $color-light 0%, $color-light 65%, $color-white 65%);

    &--video {
        img {
            display: none;
        }
    }

    &__background {
        position: absolute;
        top: 155px;
        left: 50%;
        transform: translateX(-50%);

        svg {
            width: 2100px;
            height: 150px;
            fill: $color-white;
        }
    }

    &__item {
        width: 100%;
        height: 450px;
        position: relative;
        display: flex;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(278.41deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }

    &__media {
        width: 100%;
        height: 100%;
        background-color: $color-bg--image;
        border-radius: 5px;
        overflow: hidden;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__title {
        position: absolute;
        right: 20px;
        bottom: 100px;
        z-index: 2;
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-white;
        text-align: right;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
        padding-left: 20px !important;
        transition: $duration;
    }

    &[href] {
        &:hover,
        &:focus {
            .slideshow {
                
            }
        }
    }
}





//======================================================================================================
// Quick Access
//======================================================================================================
.qa {
    position: relative;
    z-index: 2;
    background-color: $color-light;
    padding: 25px 0 60px;

    &__effect {
        position: absolute;
        top: -115px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        svg {
            width: 2100px;
            height: 202px;
            stroke-width: 3px;
            stroke: $color-main;
            fill: transparent;
        }
    }

    &__container {
        width: 990px;
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    &-item {
        width: 140px;
        height: 185px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover,
        &:focus {
            .qa-item {
                &__icon {
                    transform: translateY(-7.5px);
                }

                &__title {
                    color: $color-main;
                }
            }
        }

        &__icon {
            width: 140px;
            height: 140px;
            background-color: $color-white;
            border-radius: $border-radius--round;
            transition: $duration;

            svg {
                width: 140px;
                height: 140px;
            }
        }

        &__title {
            font-family: $font-family;
            font-size: $font-size--text;
            line-height: 1.2em;
            font-weight: $font-weight-semibold;
            color: $color-black;
            text-align: center;
            transition: $duration;
        }
    }
}





//======================================================================================================
// Actualites
//======================================================================================================
.ac {
    padding: 80px 0 70px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__title {
        position: relative;
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-text;

        &::after {
            content: url("data:image/svg+xml,%3Csvg width='65' height='44' viewBox='0 0 65 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M56.1715 43.4634C56.9033 43.6277 57.644 43.2261 57.9057 42.5231L64.4057 25.064C64.6948 24.2876 64.2997 23.4239 63.5233 23.1349C62.747 22.8459 61.8833 23.2409 61.5943 24.0173L56.4484 37.8392C42.7375 5.919 14.7555 -0.402985 1.88374 0.597668C1.0578 0.661873 0.440302 1.38348 0.50451 2.20941C0.568718 3.03535 1.29032 3.65285 2.11626 3.58864C14.1105 2.6562 41.1 8.63486 54.0687 39.9167L36.8285 36.0467C36.0202 35.8653 35.2179 36.3734 35.0364 37.1818C34.855 37.9901 35.3631 38.7924 36.1715 38.9739L56.1715 43.4634Z' fill='%232B78AC'/%3E%3C/svg%3E");
            position: absolute;
            top: 35px;
            left: calc(100% + 15px);
            display: block !important;
        }
    }

    &-navigation {
        display: flex;
        column-gap: 15px;

        &__item {
            width: 40px;
            height: 40px;
            fill: none;
            stroke: $color-dark;
            stroke-width: 2px;
            border: 2px solid $color-dark;
            border-radius: $border-radius--round;
            transition: $duration;

            &:hover,
            &:focus {
                stroke: $color-white;
                background-color: $color-dark;
                cursor: pointer;
            }
        }
    }

    &__items {
        width: 100%;
        margin-top: 40px;
    }

    &-item {
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus {
            .ac-item {
                &__image {
                    &::after {
                        width: 100%;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }

                &__more {
                    color: $color-dark;
                }
            }
        }

        &__image {
            height: 300px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            &::after {
                content: '';
                width: 100px;
                height: 6px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                background-color: $color-main;
                border-radius: 0 $border-radius $border-radius 0;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--3;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-text;
            transition: $duration;
        }

        &__text {
            color: $color-text;
            margin-top: 5px;
            transition: $duration;
        }

        &__more {
            font-weight: $font-weight-semibold;
            color: $color-main;
            margin-top: 5px;
            transition: $duration;
        }
    }

    &__button {
        width: 270px;
        margin: 40px 0 0 auto;
    }
}





//======================================================================================================
// Agenda
//======================================================================================================
.ag {
    position: relative;
    background-color: $color-dark;
    padding: 80px 0 0;

    &::after {
        content: '';
        width: 100%;
        height: 60px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $color-black;
    }

    &__effect {
        position: absolute;
        top: 475px;
        left: 50%;
        transform: translateX(-50%);

        svg {
            width: 2104px;
            height: 235px;
            stroke-width: 3px;
            stroke: $color-third;
            fill: transparent;
        }
    }

    &__background {
        position: absolute;
        bottom: -90px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        svg {
            width: 2100px;
            height: 150px;
            fill: $color-dark;
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    &-bigitem {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin-top: 40px;
        transition: $duration;

        &:hover,
        &:focus {
            .ag-bigitem {
                &__image img {
                    transform: scale(1.1);
                }

                &__more {
                    color: $color-third;
                }
            }
        }

        &__image {
            width: 770px;
            height: 405px;
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            max-width: 330px;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__category {
            font-size: $font-size--text-small;
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-third;
            border-radius: 50px;
            padding: 2px 10px;
        }

        &__date {
            font-family: $font-family--heading;
            font-size: 24px;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-third;
            text-transform: uppercase;
            margin-top: 10px;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--2;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-white;
            margin-top: 10px !important;
        }

        &__more {
            font-weight: $font-weight-semibold;
            color: $color-white;
            margin-top: 25px;
            transition: $duration;
        }

        &__arrow {
            width: 65px;
            height: 44px;
            fill: $color-third;
            margin-top: 10px;
            margin-left: -50px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 40px;
    }

    &__smallitems-buttons {
        display: flex;
        justify-content: space-between;
    }

    &__smallitems {
        width: 890px;
        padding: 0 10px;
        margin: 0 -10px;
    }

    &-navigation {
        display: flex;
        column-gap: 15px;

        &--tablet,
        &--mobile {
            display: none;
        }

        &__item {
            width: 40px;
            height: 40px;
            fill: none;
            stroke: $color-white;
            stroke-width: 2px;
            border: 2px solid $color-white;
            border-radius: $border-radius--round;
            transition: $duration;

            &:hover,
            &:focus {
                stroke: $color-dark;
                background-color: $color-white;
                cursor: pointer;
            }
        }
    }

    &-smallitem {
        width: 270px !important;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &:hover,
        &:focus {
            .ag-smallitem {
                &__image {
                    &::after {
                        width: 100%;
                    }

                    img {
                        transform: scale(1.1);
                    }
                } 
            }
        }

        &__image {
            width: 100%;
            height: 165px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            &::after {
                content: '';
                width: 50px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                background-color: $color-third;
                border-radius: 0 $border-radius $border-radius 0;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__category {
            position: absolute;
            top: 10px;
            left: -10px;
            font-size: $font-size--text-small;
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-third;
            border-radius: 50px;
            padding: 2px 10px;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__date {
            font-family: $font-family--heading;
            font-size: $font-size--text;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-third;
            text-transform: uppercase;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-white;
            margin-top: 5px !important;
            transition: $duration;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 25px;
    }

    &__button {
        width: 230px;

        &:hover,
        &:focus {
            color: $color-text;
            background-color: $color-white;
            border-color: $color-white;
        }
    }
}





//======================================================================================================
// Saison culturelle
//======================================================================================================
.sact {
    background-color: $color-black;
    padding: 160px 0 70px;

    &--empty {
        padding: 70px 0;
    }

    &__container {
        display: flex;
        column-gap: 30px;
    }

    &__header {
        flex-shrink: 1;
        max-width: 240px;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    &__subtitle {
        position: relative;
        font-family: $font-family--heading;
        font-size: $font-size--2;
        line-height: 1.1em;
        font-weight: $font-weight-light;
        color: $color-white;
        padding-left: 30px;
        margin-top: 10px !important;

        &::before {
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $color-second;
            border-radius: $border-radius--round;
        }
    }

    &__button {
        width: 240px;
        margin-top: 25px;

        br {
            display: none;
        }
    }

    &-item {
        flex-shrink: 0;
        width: 270px;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &:hover,
        &:focus {
            .sact-item {
                &__image {
                    &::after {
                        width: 100%;
                    }

                    img {
                        transform: scale(1.1);
                    }
                } 
            }
        }

        &__image {
            width: 100%;
            height: 165px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            &::after {
                content: '';
                width: 50px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                background-color: $color-second;
                border-radius: 0 $border-radius $border-radius 0;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__category {
            position: absolute;
            top: 10px;
            left: -10px;
            font-size: $font-size--text-small;
            color: $color-white;
            text-transform: uppercase;
            background-color: $color-second;
            border-radius: 50px;
            padding: 2px 10px;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__date {
            font-family: $font-family--heading;
            font-size: $font-size--text;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-white;
            margin-top: 5px !important;
            transition: $duration;
        }
    }
}





//======================================================================================================
// Kiosque
//======================================================================================================
.kio-ct {
    position: relative;

    &__effect {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        svg {
            width: 2104px;
            height: 235px;
            stroke-width: 3px;
            stroke: $color-fourth;
            fill: transparent;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }
}

.kiosque {
    width: 520px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 40px;
    padding-top: 80px;

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin-top: 20px !important;
    }

    &-item {
        display: flex;
        column-gap: 30px;

        &__image {
            flex-shrink: 0;
            width: 270px;
            height: 385px;
            background-color: $color-bg--image;
            border: 1px solid $color-light;
            border-radius: 5px;
            overflow: hidden;

            &--no-image {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 40%;
                }
            }


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__infos {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__buttons {
            display: flex;
            column-gap: 20px;
            margin-bottom: 25px;
        }

        &__button {
            border-radius: $border-radius--round;
            transition: $duration;

            svg {
                width: 40px;
                height: 40px;
                fill: $color-dark;
                border: 2px solid $color-dark;
                border-radius: $border-radius--round;
                transition: $duration;
            }

            &:hover,
            &:focus {
                svg {
                    fill: $color-white;
                    background-color: $color-dark;
                }
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-text;
            margin-bottom: 25px !important;
        }

        &__text {
            color: $color-text;

            span {
                text-transform: uppercase;
            }
        }
    }

    &__button {
        width: 270px;
    }
}





//======================================================================================================
// Annuaire de contacts
//======================================================================================================
.ct {
    width: 500px;
    height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: $color-light;
    padding: 0 50px 75px;

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin-bottom: 40px !important;
    }

    &__content {
        position: relative;
    }

    &__choice {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 10px;
        margin-bottom: 40px;
    }

    &__label {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin: 0 0 15px;
    }

    &__select {
        position: relative;
        
        select {
            width: 330px;
            height: 60px;
            border-width: 2px;
            border-color: $color-white;
            border-radius: 5px;
            padding: 0 15px;
            appearance: none;
            transition: $duration;

            &:hover,
            &:focus {
                border-color: $color-dark;
            }
        }

        svg {
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            fill: $color-text;
            pointer-events: none;
        }
    }

    &__submit {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;
        background-color: $color-third;
        border-color: $color-third;
        border-radius: $border-radius--round;
        padding: 0;
        margin: 0;
    }

    &__text {
        font-family: $font-family--heading;
        font-size: $font-size--5;
        line-height: 1.3em;
        font-weight: $font-weight-bold;
        color: $color-text;
        margin-bottom: 15px;
    }

    &__button {
        width: 230px;
          color: $color-text;
    }

    &__arrow {
        width: 44px;
        height: 69px;
        position: absolute;
        left: -67.5px;
        bottom: 92.5px;
        fill: $color-main;
    }
}





//======================================================================================================
// Retour en images
//======================================================================================================
.retour {
    position: relative;
    background-color: $color-dark;
    padding: 85px 0 0;

    &::before {
        content: '';
        width: 100%;
        height: 30px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $color-black;
    }


    &__background {
        position: absolute;
        left: 50%;
        bottom: -120px;
        transform: translateX(-50%);

        svg {
            width: 2100px;
            height: 150px;
            fill: $color-dark;
        }
    }

    &__effect {
        position: absolute;
        left: 50%;
        bottom: -220px;
        transform: translateX(-50%);

        svg {
            width: 2104px;
            height: 235px;
            stroke-width: 3px;
            stroke: $color-second;
            fill: transparent;
        }
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-white;

        br {
            display: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    &__bloc {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    &__items {
        display: flex;
        justify-content: space-between;
        column-gap: 30px;

        &--col {
            flex-direction: column;
            row-gap: 30px;
        }
    }

    &-item {
        position: relative;
        display: flex;

        &--1,
        &--2 {
            width: 370px;
            height: 250px;
        }

        &--3 {
            width: 470px;
            height: 300px;
        }

        &--4 {
            width: 270px;
            height: 300px;
        }

        &--5 {
            width: 370px;
            height: 320px;
        }

        &--6 {
            width: 370px;
            height: 230px;
        }

        &--image {
            &[href] {
                &:hover,
                &:focus {
                    .retour-item {
                        &__media::after{
                            opacity: .8;
                        }

                        &__title {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &--video {
            &:hover,
            &:focus {
                cursor: pointer;

                .retour-item__play {
                    background-color: $color-third;
                }
            }
        }

        &__media {
            width: 100%;
            height: 100%;
            position: relative;
            background: $color-bg--image;
            border-radius: 5px;
            overflow: hidden;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $color-second;
                opacity: 0;
                z-index: 1;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }

        &__title {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: 2;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: 700;
            color: $color-white;
            text-align: center;
            padding: 0 15px !important;
            opacity: 0;
            transition: $duration;
        }

        &__play {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: 2;
            fill: $color-white;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;
        }
    }
}

.popup-area {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-black--rgba;
    opacity: 0;
    visibility: hidden;

    &.show-popup {
        visibility: visible;
        opacity: 1;
    }

    video,
    iframe {
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px;
    }

    .close-area-popup {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 60px;
        right: calc((100% - 1170px)/2);
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-second;
        border-radius: $border-radius--round;
        transition: $duration;

        &::before {
            content: '\f335';
            font: normal 30px/1 'dashicons';
            color: $color-white;
            transition: $duration;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            background-color: $color-main;

            &::before {
                color: $color-white;
            }
        }
    }
}





//======================================================================================================
// Newsletter
//======================================================================================================
.newsletter {
    background-color: $color-black;
    padding: 170px 0 90px;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    &__subtitle {
        font-size: $font-size--5;
        line-height: 1.4;
        color: $color-white;
        margin-top: 7.5px;
        max-width: 420px;
    }

    &__buttons {
        display: flex;
        column-gap: 20px;
        margin-top: 30px;
    }

    &__button {
        width: 285px;
    }

    &-social {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--3;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-white;
        }

        &__arrow {
            width: 44px;
            height: 65px;
            position: absolute;
            top: -35px;
            left: -30px;
            fill: $color-third;
        }

        &__buttons {
            display: flex;
            column-gap: 20px;
        }

        &__button {
            width: 140px;
            column-gap: 5px;

            &:hover,
            &:focus {
                color: $color-main;
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: $duration;
            }
        }
    }
}




//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        //======================================================================================================
        // SlideShow
        //======================================================================================================
        .slideshow {
            background: linear-gradient(0deg, $color-light 0%, $color-light 57%, $color-white 57%);

            &__background {
                svg {
                    width: 1680px;
                    height: 120px;
                }
            }

            &__item {
                height: 360px;
            }

            &__title {
                right: 25px;
                bottom: 55px;
                padding-left: 25px !important;
            }
        }





        //======================================================================================================
        // Quick Access
        //======================================================================================================
        .qa {
            &__effect {
                top: -150px;
                left: 77.5%;
            }

            &__container {
                width: 100%;
                padding: 0 10px;
            }
        }





        //======================================================================================================
        // Actualites
        //======================================================================================================
        .ac {
            &-item {
                &__image {
                    height: 240px;
                }
            }
        }





        //======================================================================================================
        // Agenda
        //======================================================================================================
        .ag {
            padding: 80px 0 0;

            &__effect {
                top: 395px;
            }

            &__background {
                bottom: -65px;

                svg {
                    width: 1680px;
                    height: 120px;
                }
            }

            &-bigitem {
                column-gap: 20px;

                &__image {
                    width: 620px;
                    height: 325px;
                }

                &__content {
                    max-width: 300px;
                }

                &__more {
                    margin-left: 25px;
                }

                &__arrow {
                    transform: translate(-55px, -20px);
                    margin: 0;
                }
            }

            &__content {
                row-gap: 20px;
                margin-top: 30px;
            }

            &__smallitems {
                width: 720px;
            }

            &-smallitem {
                width: 220px !important;
                row-gap: 10px;

                &__image {
                    height: 135px;
                }
            }

            &__buttons {
                margin-top: 0;
            }

            &__button {
                width: 205px;
            }
        }





        //======================================================================================================
        // Saison culturelle
        //======================================================================================================
        .sact {
            padding: 130px 0 70px;

            &--empty {
                padding: 70px 0;
            }

            &__container {
                column-gap: 20px;
            }

            &__header {
                max-width: 205px;
            }

            &__button {
                width: 200px;
                margin-top: 22.5px;

                br {
                    display: block;
                }
            }

            &-item {
                width: 220px;

                &__image {
                    height: 135px;
                }
            }
        }





        //======================================================================================================
        // Kiosque
        //======================================================================================================
        .kio-ct {
            &__effect {
                top: -25px;
                left: 60%;
            }
        }

        .kiosque {
            width: 460px;
            row-gap: 30px;

            &__title {
                margin-top: 0 !important;
            }

            &-item {
                column-gap: 20px;

                &__image {
                    width: 220px;
                    height: 315px;
                }

                &__buttons {
                    margin-bottom: 12.5px;
                }

                &__title {
                    margin-bottom: 10px !important;
                }
            }

            &__button {
                width: 220px;
            }
        }





        //======================================================================================================
        // Annuaire de contacts
        //======================================================================================================
        .ct {
            width: 460px;
            height: 565px;
            padding: 0 45px 80px;

            &__title {
                margin-bottom: 20px !important;
            }

            &__choice {
                margin-bottom: 55px;
            }

            &__select {
                select {
                    width: 300px;
                }
            }

            &__arrow {
                left: -60px;
            }
        }





        //======================================================================================================
        // Retour en images
        //======================================================================================================
        .retour {
            padding: 80px 0 0;

            &::before {
                height: 35px;
            }

            &__background {
                bottom: -85px;

                svg {
                    width: 1680px;
                    height: 120px;
                }
            }

            &__effect {
                bottom: -185px;
            }

            &__bloc {
                row-gap: 20px;
            }

            &__items {
                column-gap: 20px;

                &--col {
                    row-gap: 20px;
                }
            }

            &-item {
                &--1,
                &--2 {
                    width: 300px;
                    height: 200px;
                }

                &--3 {
                    width: 380px;
                    height: 245px;
                }

                &--4 {
                    width: 220px;
                    height: 245px;
                }

                &--5 {
                    width: 300px;
                    height: 260px;
                }

                &--6 {
                    width: 300px;
                    height: 185px;
                }
            }
        }

        .popup-area {
            .close-area-popup {
                right: calc((100% - 960px)/2);
            }
        }





        //======================================================================================================
        // Newsletter
        //======================================================================================================
        .newsletter {
            padding: 140px 0 90px;

            &__subtitle {
                margin-top: 5px;
            }

            &__button {
                &:first-child {
                    width: 260px;
                }

                &:last-child {
                    width: 220px;
                }
            }

            &-social {
                &__button {
                    width: 60px;
                    
                    span {
                        display: none;
                    }
                }
            }
        }
    }


// 960
@media screen and (max-width: $medium) {
    body {
        margin-top: 0;
    }





    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .slideshow {
        &--video {
            video {
                display: none;
            }

            img {
                display: block;
            }
        }

        &__background {
            top: 100px;

            svg {
                width: 1345px;
                height: 95px;
            }
        }

        &__item {
            height: 240px;

            &::after {
                background: linear-gradient(285.46deg, rgba(0, 0, 0, 0.2) 35.76%, rgba(0, 0, 0, 0) 100%);
            }
        }

        &__title {
            right: 20px;
            bottom: 70px;
            padding-left: 20px !important;
        }
    }





    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        &__effect {
            top: -115px;
            left: 70%;;

            svg {
                width: 1320px;
                height: 127px;
                stroke-width: 5px;
            }
        }

        &__container {
            width: 480px;
            flex-wrap: wrap;
            column-gap: 30px;
            row-gap: 30px;
            padding: 0;
        }
    }





    //======================================================================================================
    // Actualites
    //======================================================================================================
    .ac {
        padding-bottom: 60px;

        &-item {
            &__image {
                height: 155px;
            }

            &__more {
                margin-top: 10px;
            }
        }

        &__button {
            width: 300px;
        }
    }





    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        &::after {
            height: 20px;
        }

        &__effect {
            top: 455px;

            svg {
                width: 1320px;
                height: 145px;
                stroke-width: 5px;
            }
        }

        &__background {
            bottom: -80px;

            svg {
                width: 1345px;
                height: 95px;
            }
        }

        &-bigitem {
            flex-direction: column;
            align-self: stretch;
            row-gap: 30px;

            &__image {
                width: 100%;
            }

            &__content {
                max-width: 100%;
            }

            &__more {
                margin: 15px 0 0;
            }

            &__arrow {
                transform: translate(-80px, -20px);
            }
        }

        &__content {
            margin-top: 15px;
        }

        &-navigation {
            display: none;

            &--tablet {
                display: flex;
            }
        }


        &__smallitems-buttons {
            flex-direction: column;
            row-gap: 50px;
        }

        &__smallitems {
            width: calc(100% + 20px);
        }

        &-smallitem {
            width: 300px !important;
            &__image {
                height: 185px;
            }
        }

        &__buttons {
            flex-direction: row;
            justify-content: space-between;
        }

        &__button {
            width: 300px;
        }
    }





    //======================================================================================================
    // Saison culturelle
    //======================================================================================================
    .sact {
        padding: 155px 0 125px;

        &--empty {
            padding: 70px 0;
        }

        &__container {
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 35px;
        }

        &__header {
            width: 300px;
            justify-content: center;
        }

        &__button {
            width: 270px;
            margin-top: 22.5px;

            br {
                display: block;
            }
        }

        &-item {
            width: 300px;

            &__image {
                height: 185px;
            }
        }
    }





    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kio-ct {
        &__effect {
            top: 130px;
            left: 65%;

            svg {
                width: 1320px;
                height: 117px;
                stroke-width: 5px;
            }
        }

        &__container {
            flex-direction: column;
        }
    }

    .kiosque {
        width: 100%;
        row-gap: 25px;
        padding: 80px 0;

        &__title {
            margin-top: 50px !important;
        }

        &-item {
            &__image {
                width: 300px;
                height: 430px;
            }

            &__buttons {
                margin-bottom: 25px;
            }

            &__title {
                margin-bottom: 20px !important;
            }
        }

        &__button {
            width: 300px;
        }
    }
    
    



    //======================================================================================================
    // Annuaire de contacts
    //======================================================================================================
    .ct {
        width: 100%;
        height: auto;
        position: relative;
        justify-content: flex-start;
        padding: 60px 0;

        &::after {
            content: '';
            width: 2000px;
            height: 100%;
            position: absolute;
            top: 0;
            left: -800px;
            z-index: -1;
            background-color: $color-light;
        }

        &__title {
            margin-bottom: 0 !important;

            br {
                display: none;
            }
        }

        &__content {
            margin-top: 30px;
        }

        &__choice {
            column-gap: 20px;
            margin-bottom: 0;
        }

        &__select {
            select {
                width: 540px;
            }
        }

        &__text {
            margin: 30px 0 0;
        }

        &__button {
            width: 300px;
            margin-top: 17.5px;
        }

        &__arrow {
            left: -50px;
        }
    }





    //======================================================================================================
    // Retour en images
    //======================================================================================================
    .retour {
        &::before {
            height: 15px;
        }

        &__background {
            bottom: -80px;

            svg {
                width: 1344px;
                height: 95px;
            }
        }

        &__effect {
            bottom: -125px;

            svg {
                width: 1320px;
                height: 140px;
                stroke-width: 5px;
            }
        }

        &__container {
            flex-direction: column;
            row-gap: 20px;
        }

        &__bloc {
            flex-direction: column;
        }

        &__items {
            &--col {
                flex-direction: row;
            }
        }

        &-item {
            &--5 {
                width: 250px;
                height: 215px;
            }

            &--6 {
                width: 350px;
                height: 215px;
            }
        }
    }

    .popup-area {
        .close-area-popup {
            right: calc((100% - 640px)/2);
        }
    }





    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        padding: 155px 0 60px;

        &__container {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 35px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .slideshow {
        .container {
            max-width: 100%;
            padding: 0;
        }

        &__background {
            display: none;
        }

        &__item::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
        }

        &__media {
            border-radius: 0;
        }

        &__title {
            width: 100%;
            right: 0;
            bottom: 30px;
            text-align: center;
            padding: 0 20px !important;
        }
    }





    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        padding: 40px 0 65px;

        &__effect {
            top: -60px;
            left: 72.5%;

            svg {
                width: 900px;
                height: 87px;
                stroke-width: 7px;
            }
        }

        &__container {
            width: 100%;
            column-gap: 20px;
            padding: 0 10px;
        }
    }





    //======================================================================================================
    // Actualites
    //======================================================================================================
    .ac {
        padding: 60px 0;

        &__header {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 10px;
        }

        &__title::after {
            top: 65px;
            left: unset;
            right: -100px;
        }

        &__items {
            margin-top: 20px;
        }

        &__button {
            margin-top: 20px;
        }
    }





    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        &::after {
            display: none;
        }

        &__effect {
            top: 350px;
            left: 55%;

            svg {
                width: 900px;
                height: 100px;
                stroke-width: 7px;
            }
        }

        &__background {
            bottom: -70px;

            svg {
                width: 895px;
                height: 65px;
            }
        }

        &-bigitem {
            row-gap: 20px;
            margin-top: 20px;

            &__image {
                height: 185px;
            }

            &__more {
                margin: 15px 0 0 55px;
            }

            &__arrow {
                transform: translate(-25px, -20px);
            }
        }

        &__content {
            margin-top: 20px;
        }

        &-navigation {
            &--tablet {
                display: none;
            }

            &--mobile {
                display: flex;
            }
        }

        &__smallitems-buttons {
            row-gap: 80px;
        }

        &__buttons {
            flex-direction: column-reverse;
            row-gap: 15px;
        }
    }





    //======================================================================================================
    // Saison culturelle
    //======================================================================================================
    .sact {
        padding: 125px 0 90px;

        &--empty {
            padding: 70px 0;
        }

        &__container {
            row-gap: 40px;
        }

        &__button br {
            display: none;
        }
    }





    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kio-ct {
        &__effect {
            top: 435px;
            left: 75%;

            svg {
                width: 900px;
                height: 80px;
                stroke-width: 7px;
            }
        }
    }

    .kiosque {
        row-gap: 30px;
        padding: 60px 0;

        &__title {
            margin-top: 25px !important;
        }

        &-item {
            flex-direction: column;

            &__buttons {
                margin: 20px 0 0;
            }

            &__title {
                margin: 25px 0 0 !important;
            }

            &__text {
                margin-top: 20px;
            }
        }
    }





    //======================================================================================================
    // Annuaire de contacts
    //======================================================================================================
    .ct {
        &__title {
            br {
                display: block;
            }
        }

        &__choice {
            column-gap: 5px;
        }

        &__select {
            select {
                width: 235px;
            }
        }

        &__arrow {
            left: -57.5px;
            bottom: 120px;
        }
    }





    //======================================================================================================
    // Retour en images
    //======================================================================================================
    .retour {
        &::before {
            height: 40px;
            bottom: -20px;
            background-color: $color-dark;
        }

        &__background {
            bottom: -90px;

            svg {
                width: 895px;
                height: 65px;
            }
        }

        &__effect {
            left: 55%;
            bottom: -65px;

            svg {
                width: 900px;
                height: 95px;
                stroke-width: 7px;
            }
        }

        &__container {
            margin-top: 30px;
        }

        &__title br {
            display: block;
        }

        &__items {
            flex-direction: column;
            row-gap: 20px;

            &--col {
                flex-direction: column;
            }
        }

        &-item {
            &--1,
            &--2 {
                width: 300px;
            }

            &--3 {
                width: 300px;
                height: 190px;
            }

            &--4 {
                width: 300px;
                height: 330px;
            }

            &--5 {
                width: 300px;
                height: 255px;
            }

            &--6 {
                width: 300px;
                height: 185px;
            }
        }
    }

    .popup-area {
        .close-area-popup {
            right: calc((100% - 320px)/2);
        }
    }





    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        padding: 135px 0 70px;

        &__container {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 40px;
        }

        &__buttons {
            flex-direction: column;
            row-gap: 15px;
            margin-top: 15px;
        }

        &__button {
            width: 300px !important;
        }
    }
}
