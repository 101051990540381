body.admin-bar {
    .header {
        top: 32px;
    }

    iframe.goog-te-menu-frame.skiptranslate {
        top: 110px !important;
    }
}

.header {
    width: 100%;
    height: 170px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    background-color: $color-white;
    transition: $duration;

    &--fixed  {
        height: 80px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

        .header {
            &__logo {
                svg {
                    width: 120px;
                    height: 57px;
                }

                &__text {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &__content {
                flex-direction: row-reverse;
                align-items: center;
                column-gap: 60px;
            }

            &-tools {
                column-gap: 0;

                &__item {
                    &--accessibility,
                    &--translate {
                        display: none;
                    }
                }
            }
        }

        #nav-main .nav-main-item .nav-main-link {
            height: 80px;
        }
    }

    &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        display: flex;

        svg {
            width: 240px;
            height: 115px;
            transition: $duration;
        }

        &__text {
            transition: $duration;
        }
    }

    &__identity {
        display: none;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        row-gap: 10px;
        transition: $duration;
    }

    // Tools
    &-tools {
        display: flex;
        column-gap: 40px;

        &__item {
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-family: $font-family--heading;
            font-size: $font-size--text;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: none;
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            transition: $duration;

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }

            &:hover,
            &:focus {
                color: $color-second;
                
                svg {
                    fill: $color-second;
                }
            }

            svg {
                width: 24px;
                height: 24px;
                fill: $color-dark;
                transition: $duration;
            }
        }
    }
}

.tools {
    // Google translate
    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 50px;
            z-index: 1;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-color: $color-main;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-main;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}

// #google_translate_element {
//     display: none;
// }

// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 78px !important;
//     left: calc(100% - ((100% - 1200px) / 2) - 475px) !important;
//     box-shadow: none !important;
// }



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}





//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .header {
            &--fixed  {
                .header {
                    &__content {
                        column-gap: 30px;
                    }
                }
            }
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     left: calc(100% - ((100% - 960px) / 2) - 475px) !important;
        // }
    }



    // 960
    @media screen and (max-width: $medium) {
        body.admin-bar {
            .header {
                top: unset;
            }

            // iframe.goog-te-menu-frame.skiptranslate {
            //     top: 182px !important;
            // }
        }

        .header {
            height: 150px;
            position: relative;
            top: unset;
            left: unset;

            &__logo svg {
                width: 200px;
                height: 95px;
            }

            &__content {
                flex-direction: row;
                column-gap: 30px;
                padding-bottom: 30px;
            }

            &-tools {
                column-gap: 20px;

                &__item {
                    svg {
                        width: 35px;
                        height: 35px;
                    }

                    span {
                        display: none;
                    }
                }
            } 
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 150px !important;
        //     left: calc(100% - ((100% - 960px) / 2) - 510px) !important;
        // }

        .privacy {
            &__container {
                flex-wrap: wrap;
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    
    }



    // 640
    @media screen and (max-width: $small) {
        // body.admin-bar iframe.goog-te-menu-frame.skiptranslate {
        //     top: 256px !important;
        // }

        .header {
            height: 210px;

            &__container {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 30px;
            }

            &__content {
                height: auto;
                column-gap: 30px;
                padding-bottom: 0;
            }
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 210px !important;
        //     left: 50% !important;
        //     transform: translateX(-50%);
        // }
    }