.breadcrumb {
    max-width: calc(100% - 40px);
    background-color: $color-dark;
    border-radius: 50px;
    padding: 10px;
    transform: translate(20px, -50%);

    ul  {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        row-gap: 5px;
    }

    li {
        a,
        span {
            position: relative;
            display: block;
            color: $color-white;
            text-align: center;
            transition: $duration;
        }

        a {
            &:hover,
            &:focus {
                color: $color-third;
            }
        }
    }

    svg {
        width: 20px;
        height: 20px;
        fill: $color-white;
    }
}





// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        border-radius: 20px;
        transform: translate(0, -50%);
    }
}