@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

//=================================================================================================
// HTML base
//=================================================================================================
html {
    font: $font-style $font-variant $font-weight list.slash(#{$font-size}, #{$line-height}) $font-family;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//=================================================================================================
// Body base
//================================================================================================= 
body {
    font-size: $font-size--text;
    color: $color-text;
    background: $color-bg--body;
}

ol,
ul {
    list-style-type: none;
}

p,
ol,
ul,
dl,
table {
    margin: 0 0 0 0;
    padding: 0;
}


//=================================================================================================
// Remove extra margin for nested lists
//================================================================================================= 
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}


//=================================================================================================
// Headings
//================================================================================================= 
h1,
h2,
h3,
h4,
h5 {
    position: relative;
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    line-height: $line-height--heading;
    color: $color-text;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin: 25px 0 10px;
}

.wysiwyg h2 {
    margin: 0px 0px 30px;
}


//=================================================================================================
// Heading individual styles
//================================================================================================= 
h1 {
    font-size: $font-size--1;
    line-height: 1.1em;
}

h2,
.gform_wrapper form h2.gsection_title,
h3,
h4 {
    &::after {
        position: absolute;
        left: 0;

    }
}

h2,
.gform_wrapper form h2.gsection_title {
    font-size: $font-size--2;

    &::after {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 122 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M52.171 3.42115C32.864 0.195075 15.4452 3.11208 1.18012 5.72349C0.636866 5.82294 0.115849 5.46316 0.016399 4.9199C-0.0830512 4.37665 0.276725 3.85563 0.819982 3.75618C15.0955 1.14287 32.8184 -1.84028 52.5006 1.4485C63.3078 3.25431 76.7499 4.68952 89.3118 4.95552C101.916 5.22242 113.483 4.30679 120.635 1.50377C121.149 1.30223 121.73 1.5557 121.931 2.0699C122.133 2.5841 121.879 3.16432 121.365 3.36585C113.814 6.3254 101.897 7.22246 89.2695 6.95507C76.5992 6.68678 63.0605 5.24071 52.171 3.42115Z' fill='%23DA2128'/%3E%3C/svg%3E");
        width: 122px;
        bottom: -20px;
    }
}

h3 {
    font-size: $font-size--3;

    &::after {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 62 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.4712 2.84123C16.9479 0.931677 8.34479 2.65412 1.21453 4.22046C0.675105 4.33896 0.141755 3.99773 0.0232569 3.45831C-0.0952411 2.91889 0.245986 2.38554 0.785408 2.26704C7.92542 0.698554 16.8932 -1.1191 26.8644 0.880259C32.2401 1.95815 38.9277 2.81472 45.1707 2.97335C51.4611 3.13319 57.1263 2.57754 60.5744 0.955827C61.0741 0.720773 61.6698 0.935365 61.9049 1.43513C62.1399 1.9349 61.9253 2.53059 61.4256 2.76565C57.5223 4.60148 51.4456 5.13344 45.1199 4.97271C38.7468 4.81077 31.9439 3.93857 26.4712 2.84123Z' fill='%23F68B28'/%3E%3C/svg%3E");
        width: 62px;
        bottom: -17.5px;
    }
}

h4 {
    font-size: $font-size--4;

    &::after {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 42 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.9153 2.55422C11.641 1.29616 5.96641 2.42858 1.21453 3.47245C0.675105 3.59095 0.141755 3.24972 0.0232569 2.7103C-0.0952411 2.17088 0.245986 1.63753 0.785408 1.51903C5.54704 0.473018 11.5863 -0.754622 18.3085 0.593253C21.8761 1.3086 26.3152 1.87704 30.4556 1.98225C34.6433 2.08866 38.3515 1.71428 40.5744 0.668823C41.0741 0.433769 41.6698 0.648362 41.9049 1.14813C42.1399 1.6479 41.9253 2.24359 41.4256 2.47864C38.7475 3.73822 34.6278 4.08891 30.4048 3.9816C26.1343 3.87309 21.5799 3.28902 17.9153 2.55422Z' fill='%232B78AC'/%3E%3C/svg%3E");
        width: 42px;
        bottom: -15px;
    }
}

h5 {
    font-size: $font-size--5;
}

h6 {
    font-size: $font-size--6;
}


//=================================================================================================
// Pictures styling
//=================================================================================================
//img:before {
//}


//=================================================================================================
// Link styling
//================================================================================================= 
a {
    color: inherit;
    text-decoration: none;
    transition: all $duration;

    &:hover,
    &:active,
    &:focus {
        color: $color-link--hover;
    }
}

.wysiwyg {
    a {
        color: $color-link;
    }
}


//=================================================================================================
// Highlight
//================================================================================================= 
mark {
    background: $color-highlight;
    padding: 0 0.2rem;
}


//=================================================================================================
// Blockquote
//================================================================================================= 
blockquote {
    font-size: 1.5rem;
    padding: 0 1.5rem;
    border-left: $borders--blockquote;
    margin: 0;

    cite {
        display: block;
        font-size: $font-size--text;
        text-align: right;
        margin-top: 0;
    }
}


//=================================================================================================
// Code block styling
//=================================================================================================
pre {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    tab-size: 2;
    padding: 1rem;
    border: 0;
    border-radius: $border-radius;
    margin: 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}


//=================================================================================================
// Keyboard input
//================================================================================================= 
kbd {
    display: inline-block;
    line-height: 1.4;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: $font-size--text-xsmall;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    background-color: #f7f7f7;
    padding: .1em .6em;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    margin: 0 .1em;
}


//=================================================================================================
// Inline code styling
//================================================================================================= 
:not(pre)>code {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}


//=================================================================================================
// Line break
//================================================================================================= 
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}


//=================================================================================================
// Definition list
//================================================================================================= 
dt {
    font-weight: $font-weight-semibold;
}

dd {
    margin-bottom: .5rem;
}


//=================================================================================================
// Image/video wrapper
//================================================================================================= 
img {
    max-width: 100%;
    height: auto;
}

.image-wrapper {
    min-height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-bg--image;
    overflow: hidden; 
}

.image-wrapper {
    video {
        margin-bottom: 10px;
    }
}


//=================================================================================================
// Gutter - Placement 
//================================================================================================= 
.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.content-right {
    text-align: right;
}

.container-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    h1 {
        font-size: 2.375rem; //38px
    }
    h2 {
        font-size: 1.750rem; //28px
    }
    h3 {
        font-size: 1.500rem; //24px
    }
    h4 {
        font-size: 1.188rem; //19px
    }
}
