.nav-main {
    &__close {
        width: 40px;
        height: 40px;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 2px solid $color-white;
        border-radius: $border-radius--round;
        padding: 0;
        margin: 0;
        transition: $duration;

        svg {
            width: 12px;
            height: 12px;
            fill: $color-white;
            transition: $duration;
        }

        &:hover,
        &:focus {
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 60px;
        transition: $duration;
    } 

    .nav-main-item {
        &.menu-item-has-children:hover, 
        &.menu-item-has-children:focus-within {
            .nav-main-link::before {
                background-color: $color-third;
            }

            .sub-menu {
                display: flex;
            }
        }

        .nav-main-link {
            width: 100%;
            height: 90px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-text;

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 42.5%;
                left: -7.5px;
                transform: translateY(-50%);
                background-color: transparent;
                border-radius: $border-radius--round;
                z-index: -1;
                transition: $duration;
            }
        }

        .sub-menu {
            width: 100%;
            position: absolute;
            left: 0;
            display: none;
            padding: 30px 15px;
            z-index: 0;

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $color-dark;
                opacity: .95;
                z-index: -1;
            }

            &__close {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                display: none;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                padding: 0;
                margin: 0;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, 
            .nav-grandchild-item {
                width: 100%;
                display: inline-flex;
                margin: -1px; // Fix space beetween inline-flex

                a {
                    width: 100%;
                    position: relative;
                    display: flex;
                    font-weight: $font-weight-bold;
                    color: $color-white;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid rgba(255, 255, 255, .5);

                    &:hover,
                    &:focus {
                        padding-left: 15px;
                        
                        &::before {
                            width: 100%;
                        }

                        &::after {
                            color: $color-third;
                        }
                    }

                    &::before {
                        content: "";
                        width: 0;
                        height: 2px;
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        background: $color-third;
                        transition: $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: rgba(255, 255, 255, .5);
                        transition: all $duration;
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    font-weight: $font-weight-normal;
                    border-bottom: 0;
                    padding: 17px 0 17px 30px;

                    &:hover,
                    &:focus {
                        padding-left: 37.5px;
                    }

                    &::before {
                        display: none;
                    }

                    &::after {
                        right: unset;
                        left: 5px;
                    }
                } 
            }
        }
    }
}

.nav-main_button {
    display: none;
}

.nav-main_icon {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-family: $font-family--heading;
    font-size: $font-size--4;
    font-weight: $font-weight-bold; 
    color: $color-text;
    background-color: transparent;
    margin: 0;
    transition: $duration;

    .navicon {
        width: 18px;
        height: 2px;
        position: relative;
        top: -1px;
        display: block;
        background: $color-dark;
        transition: $duration;

        &:before, 
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            display: block;
            background: $color-dark;
            transition: $duration;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: -6px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-main .menu__nav__list {
            column-gap: 50px;
        }
    }


// 960
@media screen and (max-width: $medium) {
    body.admin-bar .nav-main__content {
        top: 32px;
    }

    .nav-main {   
        &--active {
            .nav-main__content {
                left: 0;
            }
        }
        
        &__content {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            background-color: $color-dark;
            padding: 50px 30px;
            transition: $duration;
        }

        &__close {
            display: flex;
        }

        .menu__nav__list {
            flex-direction: column;
        }

        .nav-main-item {
            width: 100% !important;  

            &.menu-item-has-children {
                &:hover,
                &:focus {
                    .nav-main-link::before {  
                        background-color: transparent;
                    }
                }

                &.active {
                    .nav-main-link::before {  
                        background-color: $color-third;
                    }
    
                    .sub-menu {
                        left: 0;
                    }
                }
            }

            .nav-main-link {
                height: auto;
                justify-content: flex-start !important;
                color: $color-white;
                padding: 20px 0;

                &::before {
                    left: -7.5px;
                }
            }

            .sub-menu {
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 100%;
                z-index: 1;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                padding: 50px 0;
                transition: $duration;

                &::after {
                    opacity: 1;
                }

                &__close {
                    display: flex;
                    margin-left: 30px;
                }

                ul {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 30px; 
                    overflow-y: auto;
                    
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
        }
    }
    
}